import { getItemAnon, removeItemAnon, setItemAnon } from "utils/localstorage";
import _ from "lodash";
import { forwarderClient } from "utils/clients";

export const defaults = {};

const merge = (base, suffix) => {
    if(base.endsWith("/")) {
        return base + suffix;
    } else {
        return base + "/" + suffix;
    }
}

const externals = {
    platformBackend: process.env.REACT_APP_PLATFORM_BACKEND_URL,
    portalFrontend: process.env.REACT_APP_PORTAL_FRONTEND,
    portalBackend: process.env.REACT_APP_PORTAL_BACKEND,
    gradualDeployBackend: process.env.REACT_APP_GRADUAL_DEPLOY_API_URL,
    uiNotificationWebSocket: process.env.REACT_APP_UI_NOTIFICATION_WS,
    uiNotificationBackend: process.env.REACT_APP_UI_NOTIFICATION_URL,
    saasFrontend: process.env.REACT_APP_SAAS_FRONTEND,
    addServiceConnectorFrontend: process.env.REACT_APP_ADD_SERVICE_CONNECTOR_FRONTEND,
    tenancyBackend: process.env.REACT_APP_TENANCY_API_URL,
    tenancyV4Backend: process.env.REACT_APP_TENANCY_V4_URL,
    saasMp: process.env.REACT_APP_SAAS_MP_API_URL,
    agentsBackend: process.env.REACT_APP_AGENTS_API_URL,
    ipaBackend: process.env.REACT_APP_IPA_API_URL,
    backupServiceBackend: process.env.REACT_APP_BACKUP_API_URL,
    licenseServiceBackend: process.env.REACT_APP_LICENSE_API_URL,
    subscriptionServiceBackend:process.env.REACT_APP_SUBSCRIPTION_API_URL,
    credentialServiceBackend: process.env.REACT_APP_CREDENTIAL_API_URL,
    forwarderBackend: process.env.REACT_APP_FORWARDER_BACKEND,
    auditBackend: process.env.REACT_APP_IS_DARK === "true" ? merge(process.env.REACT_APP_OCCM_ENDPOINT, "occm/api/audit/local") : process.env.REACT_APP_PLATFORM_BACKEND_URL,
    spotWrapperBackend: process.env.REACT_APP_SPOT_WRAPPER_URL,
    spotIframeUrl: process.env.REACT_APP_SPOT_ENDPOINT,
    intercomId: process.env.REACT_APP_INTERCOM_APP_ID,
    nssClientId: process.env.REACT_APP_NSS_CLIENT_ID,
    cvsServiceBackend: process.env.REACT_APP_CVS_API_URL,
    cleanIT: process.env.REACT_APP_CLEAN_IT_URL,
    cleanITAccount: process.env.REACT_APP_CLEAN_IT_ACCOUNT,
    notificationsService:process.env.REACT_APP_ALERTS_AND_NOTIFICATIONS_URL,
    supportSite:process.env.REACT_APP_SUPPORT_SITE,
    googleAnalyticsTag: process.env.REACT_APP_GOOGLE_ANALYTICS_TAG,
    compliancereportBackend:process.env.REACT_APP_COMPLIANCE_REPORT_API_URL,
    serviceIframes: {
        "cvo-angular": process.env.REACT_APP_ANGULAR_ENDPOINT,
        "NetApp.OntapCVO/iframe/create-cvo": process.env.REACT_APP_CVO_SERVICES_ENDPOINT,
        "NetApp.OntapONPrem/iframe/create-onprem": process.env.REACT_APP_ON_PREM_SERVICES_ENDPOINT,
        "NetApp.Replication/iframe/create": process.env.REACT_APP_REPLICATION_SERVICES_ENDPOINT,
        "cloud-backup": process.env.REACT_APP_SFR_IFRAME_ENDPOINT,
        "cloud-insights": process.env.REACT_APP_MONITORING_IFRAME_ENDPOINT,
        "cvs": process.env.REACT_APP_CVS_IFRAME_ENDPOINT,
        "active-iq": process.env.REACT_APP_ACTIVE_IQ_IFRAME_ENDPOINT,
        "digital-advisor": process.env.REACT_APP_DIGITAL_ADVISOR_IFRAME_ENDPOINT,
        "cloud-sync": process.env.REACT_APP_CLOUD_SYNC_IFRAME_ENDPOINT,
        "cloud-tiering": process.env.REACT_APP_CLOUD_TIERING_IFRAME_ENDPOINT,
        "snapcenter": process.env.REACT_APP_SNAPCENTER_IFRAME_ENDPOINT,
        "sc_hybrid": process.env.REACT_APP_SC_HYBRID_IFRAME_ENDPOINT,
        "scv_hybrid": process.env.REACT_APP_SCV_HYBRID_IFRAME_ENDPOINT,
        "ase-fluid": process.env.REACT_APP_FLUID_IFRAME_ENDPOINT,
        "cloud-compliance": process.env.REACT_APP_COMPLIANCE_IFRAME_ENDPOINT,
        "risk-remediation": process.env.REACT_APP_RISK_REMEDIATION_IFRAME_ENDPOINT,
        "remediation-status": process.env.REACT_APP_REMEDIATION_STATUS_IFRAME_ENDPOINT,
        "economic-efficiency": process.env.REACT_APP_ECONOMIC_EFFICIENCY_IFRAME_ENDPOINT,
        "keystone": process.env.REACT_APP_KEYSTONE_IFRAME_ENDPOINT,
        "storage-services":process.env.REACT_APP_STORAGE_IFRAME_ENDPOINT,
        "cloud-storage":process.env.REACT_APP_CLOUD_STORAGE_IFRAME_ENDPOINT,
        "eseries":process.env.REACT_APP_ESERIES_IFRAME_ENDPOINT,
        "storage-grid": process.env.REACT_APP_STORAGE_GRID_IFRAME_ENDPOINT
    },
    serviceBackends: {
        "cloud-sync": process.env.REACT_APP_CLOUD_SYNC_API_URL,
        "tiering": process.env.REACT_APP_TIERING_API_URL,
        "fsx": process.env.REACT_APP_FSX_API_URL,
        "snapcenter":process.env.REACT_APP_SNAPCENTER_API_URL,
        "storage-services":process.env.REACT_APP_STORAGE_API_URL,
        "cloud-storage":process.env.REACT_APP_CLOUD_STORAGE_API_URL,
        "eseries":process.env.REACT_APP_ESERIES_API_URL,
        "ontap-service": process.env.REACT_APP_ONTAP_API_URL,
        "database-services":process.env.REACT_APP_DATABASE_API_URL,
    }
};

const endpointRegex = /^(?<service>[^/]+)\/(?<purpose>[^/]+)\/(?<key>[^/]+$)/;

export const setExternals = ({supportServices, isDemo, servicesList, environment}) => {
    if(supportServices) {
        externals.platformBackend = supportServices.platformServiceInformation?.url;
        externals.portalFrontend = supportServices.portalService.portalInformation?.portalFrontEnd;
        externals.portalBackend = supportServices.portalService.portalInformation?.portalBackEnd;
        externals.saasFrontend = supportServices.portalService.saasFrontEnd;
        externals.tenancyBackend = supportServices.tenancyServiceInformation?.url;
        /**
         * This change is added to set the api url for tenancy incase of connector local and restricted side.
         * TODO this varaibles to be removed when the support-service api will support the new endpoint.
         */
        if (environment) {
            const stageEnv = environment === "production" ? "" : `${environment}.`;
            externals.tenancyV4Backend = `https://${stageEnv}api.bluexp.netapp.com/v1/management`;
        }
        externals.ipaBackend = supportServices.ipaServiceInformation?.url;
        externals.backupServiceBackend = supportServices.servicesInformation?.cbs?.apiUrl;
        externals.licenseServiceBackend = supportServices.licenseServiceInformation?.url;
        externals.credentialServiceBackend = supportServices.credentialsServiceInformation?.url;
        externals.saasMp = supportServices.saasMpServiceInformation?.url;
        externals.auditBackend = process.env.REACT_APP_IS_DARK === "true" ? merge(process.env.REACT_APP_OCCM_ENDPOINT, "occm/api/audit/local") : supportServices.platformServiceInformation?.url;
        externals.agentsBackend = supportServices.tenancyServiceInformation?.agentsMgmtUrl;
        externals.forwarderBackend = supportServices.tenancyServiceInformation?.forwarderUrlOverride;
        externals.intercomId = supportServices.intercomEnabled ? supportServices.intercomAppId : "";
        externals.uiNotificationWebSocket =  supportServices.notificationServiceInformation?.wsUrl;
        externals.uiNotificationBackend = supportServices.notificationServiceInformation?.url;
        externals.notificationsService = supportServices.alertsAndNotificationServiceInformation?.url;
        externals.serviceBackends["ontap-service"] = supportServices.platformServiceInformation?.url + "/ontap-service"
        externals.cvsServiceBackend = supportServices.cvsServiceInformation?.url;

        externals.serviceIframes["cloud-backup"] = _.get(supportServices, "servicesInformation.cbs.iframeUrl");
        externals.serviceIframes["cloud-insights"] = _.get(supportServices, "servicesInformation.monitoring.iframeUrl");
        externals.serviceIframes["cvs"] = _.get(supportServices, "cvsInformation");
        externals.serviceBackends["cloud-sync"] = _.get(supportServices, "servicesInformation.cloudSync.apiUrl");
        externals.serviceBackends["tiering"] = _.get(supportServices, "servicesInformation.cloudTiering.apiUrl");
        externals.serviceIframes["active-iq"] = _.get(supportServices, "servicesInformation.activeIq.iframeUrl");
        externals.serviceIframes["digital-advisor"]=_.get(supportServices, "servicesInformation.digitalAdvisor.iframeUrl");
        externals.serviceIframes["cloud-sync"] = _.get(supportServices, "servicesInformation.cloudSync.iframeUrl");
        externals.serviceIframes["cloud-tiering"] = _.get(supportServices, "servicesInformation.cloudTiering.iframeUrl");
        externals.serviceBackends["snapcenter"] = _.get(supportServices, "servicesInformation.snapCenter.apiUrl");
        externals.serviceIframes["snapcenter"] = _.get(supportServices, "servicesInformation.snapCenter.iframeUrl");
        externals.serviceIframes["ase-fluid"] = _.get(supportServices, "servicesInformation.fluid.iframeUrl");
        externals.serviceIframes["cloud-compliance"] = _.get(supportServices, "servicesInformation.compliance.iframeUrl");
        externals.serviceBackends["fsx"] = _.get(supportServices, "servicesInformation.fsx.apiUrl");
        externals.serviceBackends["keystone"] = _.get(supportServices, "servicesInformation.keystone.iframeUrl");
        externals.serviceIframes["ransomware-protection"] = _.get(supportServices, "servicesInformation.ransomwareprotection.iframeUrl");
        externals.serviceIframes["NetApp.SystemManager/iframe/systemmanager"] = _.get(supportServices, "servicesInformation.systemmanager.iframeUrl");
        externals.serviceIframes["NetApp.OntapCVO/iframe/create-cvo"] = _.get(supportServices, "servicesInformation.cvo.iframeUrl");
        externals.serviceIframes["NetApp.OntapONPrem/iframe/create-onprem"] = _.get(supportServices, "servicesInformation.cvo.iframeUrl");
        externals.serviceIframes["NetApp.Replication/iframe/create"] = _.get(supportServices, "servicesInformation.cvo.iframeUrl");
        
        forwarderClient.defaults.baseURL = externals.forwarderBackend;
    }

    if(isDemo) {
        externals.serviceBackends["cloud-sync"] = process.env.REACT_APP_CLOUD_SYNC_DEMO_API_URL;
        externals.serviceBackends["tiering"] = process.env.REACT_APP_TIERING_DEMO_API_URL;
        externals.serviceIframes["cloud-sync"] = process.env.REACT_APP_CLOUD_SYNC_DEMO_IFRAME_ENDPOINT;
        externals.serviceIframes["cloud-tiering"] = process.env.REACT_APP_CLOUD_TIERING_DEMO_IFRAME_ENDPOINT;
        externals.serviceBackends["storage-services"] = process.env.REACT_APP_STORAGE_DEMO_API_URL;
        externals.serviceBackends["cloud-storage"] = process.env.REACT_APP_CLOUD_STORAGE_DEMO_API_URL;
        externals.serviceBackends["eseries"] = process.env.REACT_APP_ESERIES_DEMO_API_URL;
        externals.serviceIframes["storage-services"] = process.env.REACT_APP_STORAGE_DEMO_IFRAME_ENDPOINT;
        externals.serviceIframes["cloud-storage"] = process.env.REACT_APP_CLOUD_STORAGE_DEMO_IFRAME_ENDPOINT;
        externals.serviceIframes["eseries"] = process.env.REACT_APP_ESERIES_DEMO_IFRAME_ENDPOINT;
    }

    if(servicesList) {
        const servicesWithEndpoints = servicesList.filter(service => service.uiIntegration?.endpoints);
        servicesWithEndpoints.forEach(service => {
            for(const endpointKey in service.uiIntegration.endpoints) {
                const endpointValue = service.uiIntegration.endpoints[endpointKey];
                const match = endpointRegex.exec(endpointKey);
                if(match) {
                    const { purpose } =  match.groups;
                    if(purpose === "iframe") {
                        externals.serviceIframes[endpointKey] = endpointValue;
                    }
                }
            }
        })
    }

    Object.assign(defaults, _.cloneDeep(externals));

    const externalOverrides = getItemAnon({itemKey: "externals"});

    if(externalOverrides) {
        _.map(externalOverrides, (val, key) => {
            _.set(externals, getNewKeyNameForOverriding(key), val);
        })
    }
};

export const restoreDefaults = () => {
    Object.assign(externals, _.cloneDeep(defaults));
    removeItemAnon({itemKey: "externals"});
}

export const overrideExternal = (key, value) => {
    _.set(externals, getNewKeyNameForOverriding(key), value);
    const externalOverrides = getItemAnon({itemKey: "externals"}) || {};
    externalOverrides[key] = value;
    setItemAnon({itemKey: "externals"}, externalOverrides);
};

const getNewKeyNameForOverriding = (key) => key.replace(/NetApp\.+/, "NetApp:")
    .replace(/Test\.+/, "Test:")
    .split(".")
    .map(a => a.replace("NetApp:", "NetApp.")
    .replace("Test:", "Test."))
;

export default externals;
