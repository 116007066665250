import { Modal, ModalHeader, ModalFooter, ModalContent, Notification, BulletList, Button, ExternalLink, Text } from "@netapp/bxp-design-system-react"
import { useSelector, useDispatch } from "react-redux";
import typography from "@netapp/bxp-design-system-react/styles/typography.module.scss";
import externals from "utils/externals";
import styles from "./ManualWeakConnectorUpgradeDialog.module.scss"

const GcpDialog = ({close}) => {
    return <Modal>
        <ModalHeader>
            Machine Type Change is Required
        </ModalHeader>
        <ModalContent>
            <Notification type="info" messageStyle={{fontSize: 13}}>
                This Connector’s machine type no longer meets the minimum CPU requirements. The machine type should be changed to n2-standard-4.
            </Notification>
            <Text bold>
                To change the machine type, follow these instructions:
            </Text>

            <BulletList liClassName={styles.li}>
                <>Go to the GCP console, select the Connector machine and stop it.</>
                <>Select Edit > Machine configuration > Machine Type > Choose the machine type <span className={typography.b}>n1&#8209;standard&#8209;4.</span> Save the changes and start the the VM instance.
                    <p style={{marginTop: 16}}><span className={typography.b}>Note: </span>Note: If the Connector has a public IP address, the Connector VM instance will get a new IP address after the machine change.</p>
                </>
                <>After the instance is up again, go to <ExternalLink variant="text" trackCategory="CurrentConnector" trackLabel="Cloud Manager SaaS" href={externals.saasFrontend}>{externals.saasFrontend}</ExternalLink>
                </>
            </BulletList>
        </ModalContent>
        <ModalFooter>
            <ExternalLink
                trackCategory="CurrentConnector"
                trackLabel="Go To Console - Manual Upgrade"
                href="https://console.cloud.google.com/compute/instances"
                newTab
            >Go to the GCP Console</ExternalLink>
            <Button color="secondary" onClick={close}>Not Now</Button>
        </ModalFooter>
    </Modal>
}

const AzureDialog = ({close}) => {
    return <Modal>
        <ModalHeader>
            VM Type Change is Required
        </ModalHeader>
        <ModalContent>
            <Notification type="info" messageStyle={{fontSize: 13}}>
                This Connector’s VM type no longer meets the minimum CPU requirements. The VM type should be changed to DS3 v2.
            </Notification>
            <Text bold>
                To change the VM, follow these instructions:
            </Text>
            <BulletList liClassName={styles.li}>
                <>Go to the Azure portal and open the page for the Connector VM.</>
                <>Select Size, choose the DS3 v2 <span className={typography.b}>DS3 v2</span> VM type, and then click Resize.
                    <p style={{marginTop: 16}}><span className={typography.b}>Note: </span>Note: If the Connector has a public IP address, the Connector VM instance will get a new IP address after the machine change.</p>
                </>
                <>After the instance is up again, go to <ExternalLink trackCategory="CurrentConnector" trackLabel="Cloud Manager SaaS" href={externals.saasFrontend}>{externals.saasFrontend}</ExternalLink>
                </>
            </BulletList>
        </ModalContent>
        <ModalFooter>
            <ExternalLink
                trackCategory="CurrentConnector"
                trackLabel="Go To Console - Manual Upgrade"
                href="https://portal.azure.com/"
                newTab
            >Go to the Azure Console</ExternalLink>
            <Button color="secondary" onClick={close}>Not Now</Button>
        </ModalFooter>
    </Modal>
}

const AwsDialog = ({close}) => {
    return <Modal>
        <ModalHeader>
            Instance Type Change is Required
        </ModalHeader>
        <ModalContent>
            <Notification type="info" messageStyle={{fontSize: 13}}>
                This Connector's instance no longer meets the minimum CPU requirements. The instance type should be changed to t3.xlarge.
            </Notification>
            <Text bold>
                To change the instance type, follow these instructions:
            </Text>
            <BulletList liClassName={styles.li}>
                <>Go to the AWS console, select the Connector instance and stop it.</>
                <>Select Actions > Instance Settings > Change Instance Type and then choose instance type <span className={typography.b}>t3.xlarge</span>
                    <Text style={{marginTop: 16}}><span className={typography.b}>Note: </span>Note: If the Connector has a public IP address, the Connector will get a new IP address after the instance change.</Text>
                </>
                <>After the instance is up again, go to <ExternalLink trackCategory="CurrentConnector" trackLabel="Cloud Manager SaaS" href={externals.saasFrontend}>{externals.saasFrontend}</ExternalLink>
                </>
            </BulletList>
        </ModalContent>
        <ModalFooter>
            <ExternalLink
                trackCategory="CurrentConnector"
                trackLabel="Go To Console - Manual Upgrade"
                href="https://console.aws.amazon.com/ec2/v2/home"
                newTab
            >Go to the AWS Console</ExternalLink>
            <Button onClick={close}>Not Now</Button>
        </ModalFooter>
    </Modal>
}
const OnPremDialog = ({close}) => {
    return <Modal>
        <ModalHeader>
            Instance change is required
        </ModalHeader>
        <ModalContent>
            <Notification type="info" messageStyle={{fontSize: 13}}>
                This Connector's instance no longer meets the minimum CPU requirements. This instance should be changed to a VM with at least 4 CPU cores and 14 GB of memory.
            </Notification>
        </ModalContent>
        <ModalFooter>
            <Button onClick={close}>Close</Button>
        </ModalFooter>
    </Modal>
}

const Dialog = () => {
    const agent = useSelector(state => state.tenancy.selectedAgent)
    const _provider = useSelector(state => state.currentConnector.weakConnectorDialogProvider)
    const dispatch = useDispatch();
    const provider = _provider || agent.provider;

    const close = () => {
        dispatch({
            type: "CURRENT-CONNECTOR:CLOSE-WEAK-CONNECTOR-DIALOG"
        })
    }

    if(provider === "gcp") {
        return <GcpDialog close={close}/>
    } else if (provider === "azure") {
        return <AzureDialog close={close}/>
    } else if (provider === "aws") {
        return <AwsDialog close={close}/>
    } else {
        return <OnPremDialog close={close}/>
    }
}

export default Dialog;
